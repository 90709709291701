// Generated by Framer (f318921)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["yBT6LPvdJ", "xtUq65YBL"];

const serializationHash = "framer-IOAyj"

const variantClassNames = {xtUq65YBL: "framer-v-12mkqhq", yBT6LPvdJ: "framer-v-183261b"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "yBT6LPvdJ", "Variant 2": "xtUq65YBL"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "yBT6LPvdJ"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "yBT6LPvdJ", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", intrinsicHeight: 128, intrinsicWidth: 138, loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 256, pixelWidth: 276, src: "https://framerusercontent.com/images/8aKkYiUUb0ubJSCGiIJMATlcY.png"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-183261b", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"yBT6LPvdJ"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({xtUq65YBL: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0JyYW5kb24gVGV4dCBCb2xk", "--framer-font-family": "\"Brandon Text Bold\", \"Brandon Text Bold Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-line-height": "1.56em", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-bc567dab-a014-498f-95c5-57b3fd26b32c, rgb(1, 31, 64)))"}}>Patent activity</motion.p></React.Fragment>} className={"framer-zntsxf"} fonts={["CUSTOM;Brandon Text Bold"]} layoutDependency={layoutDependency} layoutId={"O8Od1hvtI"} style={{"--extracted-r6o4lv": "var(--token-bc567dab-a014-498f-95c5-57b3fd26b32c, rgb(1, 31, 64))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></Image></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-IOAyj.framer-kwdnjc, .framer-IOAyj .framer-kwdnjc { display: block; }", ".framer-IOAyj.framer-183261b { height: 128px; overflow: visible; position: relative; width: 138px; }", ".framer-IOAyj .framer-zntsxf { flex: none; height: auto; position: absolute; right: 65px; top: -41px; white-space: pre; width: auto; }", ".framer-IOAyj.framer-v-12mkqhq.framer-183261b { aspect-ratio: 1.078125 / 1; height: var(--framer-aspect-ratio-supported, 128px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 128
 * @framerIntrinsicWidth 138
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"xtUq65YBL":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerQCpnvnhf_: React.ComponentType<Props> = withCSS(Component, css, "framer-IOAyj") as typeof Component;
export default FramerQCpnvnhf_;

FramerQCpnvnhf_.displayName = "big 4 white";

FramerQCpnvnhf_.defaultProps = {height: 128, width: 138};

addPropertyControls(FramerQCpnvnhf_, {variant: {options: ["yBT6LPvdJ", "xtUq65YBL"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerQCpnvnhf_, [{explicitInter: true, fonts: [{family: "Brandon Text Bold", source: "custom", url: "https://framerusercontent.com/assets/O5k6Mj7wtZ00a6acvallq9qvPA.otf"}]}], {supportsExplicitInterCodegen: true})